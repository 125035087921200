.displayClickableIcon {
    cursor: pointer;
}

.text1line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.exerciseStudentTable > * {
    background-color: #666 !important
}
