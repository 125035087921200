.change_password__card {
  display: flex;
  justify-content: center;
  height: 100%;
}
  
.change_password__form {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1%;
  height: 100%;
  background-color: var(--darkTheme-third);
  width: 20%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
}
  
  
.buttonChangePassword {
  background-color: rgb(0, 138, 41) !important;

  /* font-weight: 700 !important; */
}

.linkToRegisterLogin {
    background-color: var(--darkTheme-primary) !important;
    color: var(--darkTheme-secondary-text) !important;
    border-radius: 4px;
    width: 100%;
}

.buttonToRegisterLogin {
    width: 100%;
}

.buttonToRegisterLogin span {
    font-weight: 700 !important;
    width: 100%;
    color: var(--darkTheme-primary-text) !important;
}

.changePasswordRequestSuccess {
    color: green !important;
}

.changePasswordRequestFailed {
    color: red !important;
}
