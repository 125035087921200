.displayClickableIcon {
    cursor: pointer;
}

.text1line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.feedbacks{
    display:flex;
    align-items: center;
}

.feedbacks fieldset{
    transform: scale(0.75);
}