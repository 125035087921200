.header{
  display:flex;
  margin-bottom: 2%;
}
.header > * {
  width:50%;
}

.header > *:first-child{
  display: flex;
}

.header > *:first-child > *{
  width: 100%;
  margin-right: 4%;
}

.header > *:last-child{
  justify-content: right;
}