
.reset_password__card {
    display: flex;
    justify-content: center;
    height: 100%;
}

.reset_password__form {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1%;
    height: 100%;
    background-color: var(--darkTheme-third);
    width: 20%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
}

.buttonReset_password {
    margin-top: 4% !important;
    background-color: rgb(0, 138, 41) !important;
    margin-bottom: 2% !important;
}

.buttonReset_password > * {
    color: white;
}

.linkToRegister {
    background-color: var(--darkTheme-primary) !important;
    border-radius: 4px;
}

.linkToLogin{
    composes: linkToRegister;
    margin-bottom: 2%;
}


.buttonToRegisterLogin {
    width: 100%;
}

.buttonToRegisterLogin span {
    font-weight: 700 !important;
    width: 100%;
    color: var(--darkTheme-primary-text) !important;
}

.passwordResetRequestSuccess {
    color: green !important;
}

.passwordResetRequestFailed {
    color: red !important;
}