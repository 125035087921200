@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap"); */

.App {
  text-align: center;
  /* font-family: "Zen Dots", cursive; */
  font-family: "Roboto", sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.MuiTableCell-root{
  font-size:1rem !important;
}

main{
  margin-bottom: 3%;
}

.Mui-required > .MuiInputLabel-asterisk{
  color:red;
}


.bg_img{
  background-image: url(/static/media/logo-hacker.be74b426.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

main > div > .MuiPaper-root:not(.MuiAlert-root){
  margin: 0% !important;
  margin-top:1% !important;
  padding: 2% !important;
  background-color: var(--darkTheme-third) !important;
}


.MuiTableBody-root > tr.MuiTableRow-root:nth-child(even), .MuiTableHead-root>tr{
  background-color: #222222 !important;
}


.row {
  border-bottom: solid 1px var(--darkTheme-primary) !important;
}

.cell {
  border-bottom: none !important;
}

.loading{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  height:100vh;
  width:100vw;
}

.MuiFormHelperText-root{
  color: #7E7E7E !important;
}


.title {
  margin: 0;
  font-weight: 500;
  color: var(--darkTheme-secondary-text) !important;
  text-align: left;
  margin: 10px;
}

.MuiContainer-root{
  margin-top:3%;
}
.Menu_menu__1Egh5 {
  background-color: var(--darkTheme-navbar);
  height: 5rem;
  max-height: 5rem;
  min-height: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left:10%;
  padding-right:10%;
  border-bottom: 1px solid black;
  position: relative;
}

a {
  color: var(--darkTheme-secondary-text);
  text-decoration: none;
}
.Menu_menu__1Egh5 a img {
  height: 80%;
}

.Menu_nav__links__3puGG {
  margin-right: auto;
  display: flex;
}

.Menu_nav__links__3puGG > * {
  margin-left:1%;
  white-space: nowrap;
}

.Menu_nav__links__3puGG *, .Menu_right__gkd50 *{
  color:rgb(5, 226, 5);
}

.Menu_button__3jaig {
  text-decoration: none;
  border-radius: 4px;
  padding: 8px 8px 8px 8px;
  font-family: "Zen Dots", cursive;
}

@-webkit-keyframes Menu_effect--pulse__2dEh6 {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes Menu_effect--pulse__2dEh6 {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.Menu_effectPulse__3y4Y_ {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.Menu_effectPulse__3y4Y_:focus,
.Menu_effectPulse__3y4Y_:active,
.Menu_logo__link__1LBVW:focus + .Menu_nav__links__3puGG > *:nth-child(1),
.Menu_effectPulse__3y4Y_:hover {
  -webkit-animation-name: Menu_effect--pulse__2dEh6;
  animation-name: Menu_effect--pulse__2dEh6;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.Menu_right__gkd50{
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 999;
}

.Menu_dropdown__3oMlQ {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Menu_dropdown__3oMlQ:hover{
  cursor:pointer;
}

.Menu_dropdown__3oMlQ *{
  color:rgb(5, 226, 5);

}

.Menu_dropdown__3oMlQ:active,
.Menu_dropdown__3oMlQ:focus {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: 1s;
}

.Menu_dropdown__3oMlQ:active ~ .Menu_dropdownContent__2xEJ3,
.Menu_dropdown__3oMlQ:focus ~ .Menu_dropdownContent__2xEJ3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Menu_dropdownContent__2xEJ3 {
  display: none;
  position: absolute;
  top:5rem;
  background-color: var(--darkTheme-navbar);
  width: 12%;
  z-index: 1000;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.Menu_dropdownContent__2xEJ3:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Menu_dropdownContent__2xEJ3 a {
  padding: 10px 10px 10px 10px;
  text-align: center;
}

.Menu_dropdownContent__2xEJ3 a:hover,
.Menu_dropdownContent__2xEJ3 Link:hover {
  background-color: var(--darkTheme-secondary);
}


.Menu_logo__link__1LBVW {
  display: flex;
  align-items: center;
  height:100%;
}


.Menu_beta__2lQWj{
  padding-left:30px;
  width:200px;
  height:50px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-weight: bold;
}

.Menu_betacontainer__12ACY{
  position: absolute;
  right:0;
  overflow:hidden;
  height:100%;
}


.ActivateAccount_activate_account__card__32oEk {
    display: flex;
    justify-content: center;
    height: 100%;
}

.ActivateAccount_activate_account__form__1coHD {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1%;
    height: 100%;
    background-color: var(--darkTheme-third);
    width: 20%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
}


.ActivateAccount_buttonActivate_account__1Ro0I {
    background-color: rgb(0, 138, 41) !important;

    /* font-weight: 700 !important; */
}

.ActivateAccount_linkToRegisterLogin__Me_4x {
    background-color: var(--darkTheme-primary) !important;
    border-radius: 4px;
    margin-bottom: 2%;
}

.ActivateAccount_buttonToRegisterLogin__3YWAu {
    width: 100%;
}

.ActivateAccount_buttonToRegisterLogin__3YWAu span {
    font-weight: 700 !important;
    width: 100%;
    color: var(--darkTheme-primary-text) !important;
}

.ActivateAccount_activate_accountRequestSuccess__byIO7 {
    color: green !important;
}

.ActivateAccount_activate_accountRequestFailed__MO4pG {
    color: red !important;
}


.BusinessProfile_businessProfileList__1lNSi {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.BusinessProfile_profiles__littleHeader__2uhZn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 20px 0px 20px;
  color: var(--darkTheme-secondary-text);
}


.BusinessProfile_dialog__content__1Ke59 label {
  margin-top: 5px;
}

.BusinessProfile_dialog__content__1Ke59 input {
  margin-top: 5px;
}

.BusinessProfile_content__28_G7{
  margin-top:5%;
}

.BusinessProfile_header__3PluE{
  display: flex;
  margin-bottom: 1%;
}

.BusinessProfile_header__3PluE > *:last-child:not(:first-child){
  height: 100%;
}


/* ---------------- Business Profile Item -----------------*/
.BusinessProfile_card__Tm-m0 {
  width: 300px;
  margin: 20px;
  text-align: left;
  background-color: #1c1c1c !important;
  color: var(--darkTheme-primary-text) !important;
  border: solid 1px var(--darkTheme-border-secondary) !important;
  border-bottom: none !important;
  height: 100%;
}

.BusinessProfile_card__title__EzdOS {
  font-size: x-large;
  width: 100%;
  margin-bottom: 10px;
}

.BusinessProfile_card__content__1RElr {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.BusinessProfile_card__actions__2pEdl {
  height: 44px;
}

.BusinessProfile_card__expanded__1IHd6 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: auto !important;
}

.BusinessProfile_card__expand__2aO7D {
  margin-left: auto !important;
}

.BusinessProfile_card__collapse__2SEna {
  /* Ne s'affiche pas exactement comme il faut */
  background-color: #1c1c1c !important;
  z-index: 1;
}

.BusinessProfile_card__item__251au {
  display: grid;
  grid-template: 100% / 25% 75%;
  margin-bottom: 4px;
}

.EditBusinessSessionDialogBox_dialogContentFlexContainer__2PxEK {
  display: flex;
  direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
}

.EditBusinessSessionDialogBox_dialogContentFlexItem__1CKzY {
  margin-top: 3% !important;
}
.BusinessSessionAvailableList_header__2HrQb{
  display:flex;
  margin-bottom: 2%;
}
.BusinessSessionAvailableList_header__2HrQb > div {
  width:50%;
}

.BusinessSessionAvailableList_header__2HrQb > *:first-child{
  display: flex;
}

.BusinessSessionAvailableList_header__2HrQb > *:first-child > *{
  width: 100%;
  margin-right: 4%;
}

.BusinessSessionAvailableList_header__2HrQb > *:last-child:not(:first-child){
  margin-left: auto;
}
.BusinessSessionList_header__1Btsj{
  display:flex;
  margin-bottom: 2%;
}
.BusinessSessionList_header__1Btsj > *:not(:last-child) {
  width:50%;
}

.BusinessSessionList_header__1Btsj > *:first-child{
  display: flex;
}

.BusinessSessionList_header__1Btsj > *:first-child > *{
  width: 100%;
  margin-right: 4%;
}

.BusinessSessionList_header__1Btsj > *:last-child{
  margin-left: auto;
}
.BusinessSessionResultsExerciseItem_displayClickableIcon__3Lw_H {
    cursor: pointer;
}

.BusinessSessionResultsExerciseItem_text1line__3RCFy {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.BusinessSessionResultsExerciseItem_exerciseStudentTable__SKstV > * {
    background-color: #666 !important
}

.MyBusinessSessionList_header__29oll{
  display:flex;
}
.MyBusinessSessionList_header__29oll > * {
  width:50%;
}

.MyBusinessSessionList_header__29oll > *:first-child{
  margin-top: 1%;
  display: flex;
}

.MyBusinessSessionList_header__29oll > *:first-child > *{
  width: 100%;
  margin-right: 4%;
}

.MyBusinessSessionList_header__29oll > *:first-child > *:last-child{
  margin-right: 0%;
}

.MyBusinessSessionList_header__29oll > *:last-child{
  justify-content: right;
}


.MyBusinessSessionList_title__vJay6{
  display:flex;
  align-items: center;
}

.MyBusinessSessionList_title__vJay6 > *:first-child{
  width:50%
}

.MyBusinessSessionList_id__1hnqq{
  margin-left:auto;
  justify-content:center;
  display:flex;
  align-items:center;
}

.MyBusinessSessionList_id__1hnqq > *:last-child{
  margin-left: 4px;
  display: flex;
}
.ChangePassword_change_password__card__2eWTu {
  display: flex;
  justify-content: center;
  height: 100%;
}
  
.ChangePassword_change_password__form__1TV2u {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1%;
  height: 100%;
  background-color: var(--darkTheme-third);
  width: 20%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
}
  
  
.ChangePassword_buttonChangePassword__pCSSI {
  background-color: rgb(0, 138, 41) !important;

  /* font-weight: 700 !important; */
}

.ChangePassword_linkToRegisterLogin__LRTno {
    background-color: var(--darkTheme-primary) !important;
    color: var(--darkTheme-secondary-text) !important;
    border-radius: 4px;
    width: 100%;
}

.ChangePassword_buttonToRegisterLogin__34YwD {
    width: 100%;
}

.ChangePassword_buttonToRegisterLogin__34YwD span {
    font-weight: 700 !important;
    width: 100%;
    color: var(--darkTheme-primary-text) !important;
}

.ChangePassword_changePasswordRequestSuccess__1f34B {
    color: green !important;
}

.ChangePassword_changePasswordRequestFailed__2e1qT {
    color: red !important;
}

code{
  color:pink;
}

.ExerciseDetails_detailsPaper__1pG0m div, .ExerciseDetails_detailsPaper__1pG0m p, .ExerciseDetails_detailsPaper__1pG0m .ExerciseDetails_MuiTypography-root__24kJB, .ExerciseDetails_detailsPaper__1pG0m h2, .ExerciseDetails_detailsPaper__1pG0m ol, .ExerciseDetails_detailsPaper__1pG0m input{
  color:white;
}

.ExerciseDetails_detailsPaper__1pG0m{
  text-align: left;
}

.ExerciseForm_toxTinymce__27Jzq{
  margin-top:2%;
  margin-bottom: 2%;
}

.ExerciseForm_chips__1DkjW{
  display: flex;
  flex-wrap: wrap;
}
.ExerciseForm_chip__1e8FQ{
  margin: 2px !important;
}

.ExerciseForm_fileInputs__Eb_0o{
  margin:auto;
  padding: 2%;
  margin-left:8%;
  width:100%;
  justify-content: center;
}

.ExerciseForm_formContainer__39upx .MuiFormControl-root{
  width:40% !important;
  margin-left:1% !important;
}


.ExerciseForm_skillsSelector__3lkJX{
  width:50%
}

.ExerciseForm_fileInputs__Eb_0o > div{
  margin-bottom: 1%;
  width:50%;
  display: flex;
  justify-content: space-between;
}
.ExerciseList_exercise__littleHeader__3ViRq {
  margin: 0px 20px 0px 20px;
}

.ExerciseList_exercise__add_button__3JXo_ {
  margin-left: auto !important;
}

.ExerciseList_title__V6wyu {
  margin: 0;
  font-weight: 500;
  color: var(--darkTheme-secondary-text) !important;
  text-align: left;
  margin: 10px;
}

.ExerciseList_table__container__2LH1d  >  .MuiTableContainer-root > .MuiTable-root > .MuiTableBody-root > tr.MuiTableRow-root:nth-child(even){
  background-color: #202020 !important;
}

.ExerciseList_table__container__2LH1d  >  .MuiTableContainer-root > .MuiTable-root > .MuiTableBody-root > tr.row.MuiTableRow-root:nth-child(4n-1){
  background-color: #222222 !important;
}

.ExerciseList_header__3ODPy{
  display:flex;
  margin-bottom: 2%;
}
.ExerciseList_header__3ODPy > *:not(:last-child) {
  width:50%;
}

.ExerciseList_header__3ODPy > *:first-child{
  display: flex;
}

.ExerciseList_header__3ODPy > *:first-child > *:last-child{
  width: 100%;
  margin-left: 4%;
}

.ExerciseList_header__3ODPy > *:last-child{
  margin-left: auto;
}
.SkillList_header__1lb_A{
  display:flex;
  margin-bottom: 2%;
}
.SkillList_header__1lb_A > div {
  width:50%;
}


.SkillList_header__1lb_A > *:first-child{
  display: flex;
}

.SkillList_header__1lb_A > *:first-child > *{
  width: 100%;
  margin-right: 4%;
}

.SkillList_header__1lb_A > *:last-child:not(:first-child){
  margin-left: auto;
}
.ExerciseItem_displayClickableIcon__38QXU {
    cursor: pointer;
}

.ExerciseItem_text1line__2fyOC {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.ExerciseItem_feedbacks__1xt5Y{
    display:flex;
    align-items: center;
}

.ExerciseItem_feedbacks__1xt5Y fieldset{
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
}
.ExerciseListForSession_feedbacks__2px7T{
  display:flex;
}

.ExerciseListForSession_feedbacks__2px7T fieldset{
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
}
.Help_help_paper__1T7GY{
  color:white !important;
  text-align: left;
}
.Login_login__card__3-3Fd {
  display: flex;
  justify-content: center;
  height: 100%;
}

.Login_center__1zobn{
  display: flex !important;
  justify-content: center;
}

.Login_login__form__3yIlQ {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 1%;
  height: 100%;
  background-color: var(--darkTheme-third);
  width: 33%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
}

.Login_login__form__3yIlQ > *{
  margin-bottom:3% !important;
}

.Login_MuiSvgIcon-root__3_-lY{
  color:white;
}


.Login_label__26BqS,
.Login_input__1zhXD Label {
  color: var(--darkTheme-secondary-text) !important;
}

.Login_input__1zhXD input {
  color: var(--darkTheme-primary-text) !important;
}

.Login_button_login__2ie3M {
  margin-top:4% !important;
  background-color: rgb(0, 138, 41) !important;
}

.Login_button_login__2ie3M > * {
  color: white;
}

.Login_link_to_register__gX1Qj {
  background-color: var(--darkTheme-primary) !important;
} 

.Login_button_to_register__1h4Mb {
  width: 100%;
}

.Login_button_to_register__1h4Mb span {
  font-weight: 700 !important;
  width: 100%;
  color: var(--darkTheme-primary-text) !important;
}

.Register_register__card__1Aii2 {
  display: flex;
  justify-content: center;
  height: 100%;
}


.Register_register__form__2N9rL {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1%;
  height: 100%;
  background-color: var(--darkTheme-third);
  width:30%;
  border-radius: 2px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
}

.Register_register__form__2N9rL > div{
  margin-bottom: 1%;
}


.Register_label__1xtd8,
.Register_input__15EGC Label {
  color: var(--darkTheme-secondary-text) !important;
}

.Register_labelError__1f2rv {
  color: red !important;
}

.Register_buttonRegister__3VgtA {
  margin-top: 3% !important;
  background-color: rgb(0, 138, 41) !important;
  color: var(--darkTheme-third-text);
  font-weight: 700 !important;
  margin-bottom: 2% !important;
}

.Register_linkToLogin__1xjPw {
  background-color: var(--darkTheme-primary) !important;
}

.Register_buttonToLogin__2wFW3 {
  width: 100%;
  border-radius: 4px;
}

.Register_buttonToLogin__2wFW3 span {
  font-weight: 700 !important;
  width: 100%;
}

span.Register_bold__1SFvu{
  font-weight: bold;
}

.ResetPassword_reset_password__card__3UBZp {
    display: flex;
    justify-content: center;
    height: 100%;
}

.ResetPassword_reset_password__form__3Qhq_ {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1%;
    height: 100%;
    background-color: var(--darkTheme-third);
    width: 20%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
}

.ResetPassword_buttonReset_password__1uOMx {
    margin-top: 4% !important;
    background-color: rgb(0, 138, 41) !important;
    margin-bottom: 2% !important;
}

.ResetPassword_buttonReset_password__1uOMx > * {
    color: white;
}

.ResetPassword_linkToRegister__km6gg {
    background-color: var(--darkTheme-primary) !important;
    border-radius: 4px;
}

.ResetPassword_linkToLogin__1cdXr{
    margin-bottom: 2%;
}


.ResetPassword_buttonToRegisterLogin__w19ZH {
    width: 100%;
}

.ResetPassword_buttonToRegisterLogin__w19ZH span {
    font-weight: 700 !important;
    width: 100%;
    color: var(--darkTheme-primary-text) !important;
}

.ResetPassword_passwordResetRequestSuccess__2oEpg {
    color: green !important;
}

.ResetPassword_passwordResetRequestFailed__3T_Lc {
    color: red !important;
}
.SequenceDetails_header__1oaMS{
  display: flex;
  justify-content: space-between;
}

.SequenceDetails_mainGrid__3p9zx{
  padding: 16px;
  display: flex;
}

.SequenceDetails_mainGrid__3p9zx > div:last-child{
  margin-left:2%
}

.SequenceDetails_sequenceExerciseListCard__3g3t3 {
  background-color: var(--darkTheme-third) !important;
}

.SequenceDetails_sequenceDetailsPrimaryButton__BbS5e:disabled {
  color: grey !important;
}

.SequenceDetails_sequenceDetailsTableRow__2mLQb th,
.SequenceDetails_sequenceDetailsTableRow__2mLQb td {
  color: var(--darkTheme-primary-text) !important;
}

.SequenceDetails_InputMinRating__2aGu8 {
  color: var(--darkTheme-primary-text) !important;
  border-color: var(--darkTheme-secondary) !important;
}

.SequenceDetails_profileListDisplay__2cPsU {
  text-align: left;
}

.SequenceDetails_main__container__2rX4v {
  display: grid;
  grid-template: 250px auto / 50% 50%;
}
.SequenceDetails_container__available__2yN1B {
  grid-area: 1 / 1 / 2 / 1;
  margin: 5px;
}

.SequenceDetails_container__profile__24VIq {
  grid-area: 1 / 2;
  margin: 5px;
  background-color: var(--darkTheme-third) !important;
}

.SequenceDetails_container__chosen__2aE2u {
  grid-area: 2 / 2;
  margin: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.SequenceList_header__32yiD{
  display:flex;
  margin-bottom: 2%;
}
.SequenceList_header__32yiD > *:not(:last-child) {
  width:50%;
}

.SequenceList_header__32yiD > *:first-child{
  display: flex;
}

.SequenceList_header__32yiD > *:first-child > *{
  width: 100%;
  margin-right: 4%;
}

.SequenceList_header__32yiD > *:last-child{
  margin-left: auto;
}
.StudentStatementDetails_stmtPaper__2IVBc div, .StudentStatementDetails_stmtPaper__2IVBc p, .StudentStatementDetails_stmtPaper__2IVBc .StudentStatementDetails_MuiTypography-root__z8p2_, .StudentStatementDetails_stmtPaper__2IVBc h2, .StudentStatementDetails_stmtPaper__2IVBc ol, .StudentStatementDetails_stmtPaper__2IVBc input{
  color:white;
}

.StudentStatementDetails_stmtPaper__2IVBc{
  text-align: left;
}

.StudentStatementDetails_error__328Br{
  font-weight: bold;
  font-size:larger;
  color:red !important;
}

.StudentStatementDetails_dateHeader__2XSog{
  width: 100%;
  text-align: right;
  color:rgb(5, 226, 5);
}
.UsersList_header__1CO7J{
  display:flex;
  margin-bottom: 2%;
}
.UsersList_header__1CO7J > * {
  width:50%;
}

.UsersList_header__1CO7J > *:first-child{
  display: flex;
}

.UsersList_header__1CO7J > *:first-child > *{
  width: 100%;
  margin-right: 4%;
}

.UsersList_header__1CO7J > *:last-child{
  justify-content: right;
}
.Footer_footer__1CI8J{
  padding:1%;
  background-color: var(--darkTheme-secondary);
  margin-top:auto;
}

.Footer_footer__1CI8J > div{
  padding-top:1%;
  width:30%;
  margin:auto;
}

.Footer_footer__1CI8J > div > div{
  display:flex;
  justify-content: space-between;
}

.Footer_footer__1CI8J a{
  min-width:20%;
  font-weight: bold;
  cursor: pointer;
}

.Footer_footer__1CI8J a:hover {
  color:green;
}

.Footer_flags__3Bi3c {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.Footer_flags__3Bi3c > *{
  margin-left:2%;
  margin-right:2%;
}


.Footer_flag__2mEHh {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.Footer_flag__2mEHh:hover {
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  transition-duration: 0.2s;
}

.Footer_flag__2mEHh:active {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
:root {
  --darkTheme-primary: #303030;
  --darkTheme-secondary: #252525;
  --darkTheme-third: #252525;

  --darkTheme-primary-text: #FFFFFF;
  --darkTheme-secondary-text: #e0e0e0;
  --darkTheme-third-text: var(--darkTheme-primary);

  --darkTheme-icons-primary: #FFFFFF;
  --darkTheme-icons-secondary: var(--darkTheme-third);

  --darkTheme-border-primary: var(--darkTheme-third);
  --darkTheme-border-secondary: var(--darkTheme-primary);

  --darkTheme-navbar: var(--darkTheme-third);

  --error-message: #c62828;
}
html {
  height: 100%;
}
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  background-color: var(--darkTheme-primary);
  color: #FFFFFF;
  color: var(--darkTheme-primary-text);
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
